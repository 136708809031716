import React from "react"
import Layout from "../../components/layout"
import GallerPage from "../../components/members/gallery"

const GalleryPage = () => {
  return (
    <Layout>  
     <GallerPage/>
    </Layout>
  )
}

export default GalleryPage
